import styled from "styled-components";

const SIStyles = {
  Container: styled.div`
    display: flex;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 200px 30px;
    width: 100%;
    max-width: 1440px;
    gap: 100px;
    @media (max-width: 1144px) {
      flex-direction: column;
      padding: 100px 30px;
    }
  `,
  Content: styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    /* width: 100%; */
    @media (max-width: 1144px) {
      width: 100%;
      text-align: center;
      align-items: center;
      /* justify-content: center; */
    }
    @media (${(props) => props.theme.breakpoints.sm}) {
      width: 100%;
      text-align: center;
    }
    h3 {
      height: 18px;
      color: #7689ff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1.78px;
      line-height: 18px;
      text-transform: uppercase;
      margin-bottom: 25px;
      width: 100%;
      @media (max-width: 1144px) {
        text-align: center;
      }
      @media (${(props) => props.theme.breakpoints.sm}) {
        margin: 0 auto 25px auto;
      }
    }
    h1 {
      color: #20243a;
      font-size: 50px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 52px;
      margin: 0 0 30px 0;
      width: 70%;
      @media (max-width: 1144px) {
        width: 100%;
      }

      @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 36px;
        line-height: 40px;
        width: 100%;
      }
    }
    p {
      height: 71px;
      max-width: 375px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 24px;
      color: #20243a;
      @media (max-width: 1144px) {
        width: 100%;
        text-align: center;
      }
      @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 16px;
        line-height: 18px;
        margin: auto;
      }
    }
    hr {
      position: relative;
      max-width: 515px;
      margin: 50px 0 32px 0;
      background-color: rgba(0, 0, 0, 0.16);
      @media (${(props) => props.theme.breakpoints.md}) {
        max-width: unset;
        margin: 40px auto 30px;
      }
    }
  `,
  IconContainer: styled.div<{ $justify?: string }>`
    display: flex;
    width: 100%;
    max-width: 515px;
    justify-content: ${(props) =>
      props.$justify ? props.$justify : "space-between"};
    gap: 10px;
    @media (max-width: 1144px) {
      justify-content: space-evenly;
      gap: 0;
    }
    @media (${(props) => props.theme.breakpoints.sm}) {
      justify-content: space-evenly;
      max-width: unset;
    }
    img {
      @media (${(props) => props.theme.breakpoints.sm}) {
        margin: 0 !important;
      }
    }
  `,
  IconImage: styled.img`
    height: 65px;
    width: auto;
    @media (${(props) => props.theme.breakpoints.sm}) {
      height: 45px;
    }
  `,
};

export default SIStyles;
