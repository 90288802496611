import styled from "styled-components"; // Use this for regular element styling
import { Form } from "formik";
import { TextField } from "@mui/material";

export const LoginStyles = {
  Container: styled.div`
    font-family: "halyard-display";
    min-height: 456px;
    max-width: 600px;
    width: 100%;
    position: relative;
    border: #caafbb 1px solid;
    background-color: #fff;
    box-shadow: 0 2px 37px 0 rgba(41, 51, 107, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px 50px 23px 50px;

    /* overflow-x: hidden; */

    @media (max-width: 768px) {
      width: 100%;
      max-width: unset;
      height: 100%;
      border: none;
      /* margin: 0; */
      /* padding: 0; */
      /* display: block; */
      /* border-radius: none; */
      /* .container {
        border: 0px transparent !important;
        box-shadow: none !important;
        background: white;
      }
      .close {
        display: none;
      } */
    }
    & .errors {
      margin-left: 10px;
      color: #c60000;
      margin-top: 0px;
      font-size: 12px;
      position: absolute;
      bottom: -2px;
    }
    & .login {
      height: 100%;
      max-height: 656px;
      max-width: 500px;
      width: 100%;
      justify-content: space-between;
    }
    & .signup {
      height: 600px;
      max-width: 500px;
    }
    & .logo {
      height: 38px;
      width: auto;
    }
    & .copy {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;

      p {
        margin: 0;
        font-size: 14px;
      }
    }
    h1 {
      height: 41px;
      // width: 456px;
      color: #20243a;
      font-family: "halyard-display";
      font-size: 29px;
      font-weight: 1;
      letter-spacing: 0;
      line-height: 33px;
      text-align: center;
    }
    h1 .addMargin {
      margin-bottom: 30px;
    }

    .button-container {
      margin: 10px;
      width: 307px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .auth-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: 50px;
        width: 262px;
        border: 1px solid #7689ff;
        border-radius: 25px;
        color: #7689ff;
        font-family: "halyard-display";
        font-size: 18px;
        font-weight: 400;
        background-color: transparent;
        gap: 10px;
        cursor: pointer;
      }
    }
    .line-break {
      position: relative;
      width: 100%;
      margin-bottom: 10px;

      hr {
        background-color: #e7e2df !important;
      }

      div {
        position: absolute;
        left: calc(50% - 25px);
        background-color: white;
        width: 50px;
        height: 20px;
        top: -12px;
        text-align: center;
        color: #372e2e;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      min-width: 307px;

      .input {
        box-sizing: border-box;
        height: 41px;
        width: 100%;
        border: 1px solid #e4dad4;
        opacity: 0.7;
        border-radius: 20.5px;
        background-color: #ffffff;
        padding: 10px;
        margin: 10px 0px;
      }
      .remember-forgot {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
      }
      .remember-me {
        display: flex;
        align-items: center;
      }
    }

    .login-button,
    .create-button {
      background-color: #7689ff;
      height: 48px;
      width: 230px;
      border: 1px solid #7689ff;
      border-radius: 25px;
      color: #ffffff;
      font-family: "halyard-display";
      font-size: 18px;
      font-weight: 100;
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin: 5px;

      div {
        margin-left: 15px;
      }
      img {
        height: 30px;
        width: 30px;
      }
    }

    .auth-icons {
      height: 22px;
      width: auto;
    }
    .button-container {
      display: flex;
      flex-direction: column;
    }
  `,

  Subtext: styled.div`
    font-size: 14px;
    margin-bottom: 5px;
    span {
      color: ${(props) => props.theme.colors.primary.main};
      font-weight: 500;
      cursor: pointer;
    }
    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.primary.main};
      font-weight: 500;
    }
  `,

  ColoredLink: styled.a`
    color: #7689ff;
    font-weight: 500;
    cursor: pointer;
  `,
  TextFieldWrapper: styled.div`
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 7px;
    width: 100%;
  `,

  Terms: styled.p`
    font-size: 12px;
    margin-bottom: 15px;
  `,

  TextField: styled(TextField)`
    && {
      height: 41px;
      border: 1px solid #f1e6e0;
      border-radius: 20.5px;
      width: 100%;
      overflow: hidden;
      background-color: white;
      margin-bottom: 5px;
      & .MuiInputBase-root {
        height: 100%;
        width: 100%;
        margin-bottom: 0;
        border-radius: 41px;
        border: none;
      }
      & .MuiOutlinedInput-input {
        height: 100%;
        width: 100%;
        border: none;
        font-size: 16px;
        color: ${(props) => props.theme.colors.neutral.dark};
        padding-left: 9px;
        font-weight: 300;
      }
      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  `,

  SubmitButton: styled.button<{
    $isValid?: boolean;
    $dirty?: boolean;
  }>`
    background-color: ${(props) =>
      props.$isValid && props.$dirty ? "#7689ff" : "#e7e2df"};
    min-height: 48px;
    width: 230px;
    border: ${(props) =>
      props.$isValid && props.$dirty ? "1px solid #7689ff" : "none"};
    border-radius: 25px;
    color: #ffffff;
    font-family: "halyard-display";
    font-size: 20px;
    font-weight: 400;
    margin: 20px auto 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 22px;
    cursor: pointer;
  `,

  ErrorMessage: styled.div`
    font-size: 12px;
    color: red;
    width: 100%;
    text-align: center;
  `,

  CloseButton: styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    height: 15px;
    width: 15px;
    cursor: pointer;
  `,

  BackToSignInButton: styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    /* height: 15px;
    width: 15px; */
    cursor: pointer;

    img {
      height: 15px;
      width: 10px;
      margin-right: 8px;
    }
  `,

  Header: styled.h1`
    height: 41px;
    // width: 456px;
    color: #20243a;
    font-family: halyard-display;
    font-size: 30px;
    font-weight: 1;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
    margin-bottom: 20px;
  `,

  PartialBorder: styled.div`
    display: inline;
    height: 1px;
    width: 40%;
    background-color: #e1e0e7;
    margin: 23px 0;
    flex: 1;
  `,

  Form: styled(Form)`
    width: 100%;
    max-width: 500px;
    height: fit-content;
    background-color: plum;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,

  WaitlistError: styled.div`
    color: #c60000;
    margin-top: 0px;
    font-size: 14px;
  `,

  SignupPrompt: styled.p`
    font-size: 14px;
  `,

  TextFieldsContainer: styled.div<{
    mobileLayout?: boolean;
    $isSecondChild?: boolean;
  }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: ${(props) =>
      !props.$isSecondChild ? "0" : props.mobileLayout ? "0" : "20px"};
    margin-bottom: 30px;
    margin-top: ${(props) =>
      props.$isSecondChild && props.mobileLayout ? "16px" : "0"};
  `,
  HelperText: styled.p`
    font-size: 12px;
    color: #91929c;
    padding-left: 9px;
    margin-bottom: 5px;
  `,
  SignUp: {
    ButtonsWrapper: styled.div`
      min-width: 307px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `,
  },
  ResetPassword: {
    Container: styled.div`
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      justify-content: space-between;
    `,
    Title: styled.h1`
      font-size: 50px;
      text-align: center;
      font-weight: 500;
    `,
    Form: styled.form`
      width: 450px;
      max-width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 24px;
    `,
    FormTitle: styled.h2`
      font-size: 39px;
      margin-bottom: 10px;
      line-height: 43px;
      font-weight: 400;
      text-align: center;
    `,
    FormSubtext: styled.div`
      font-size: 12px; 
      margin-bottom: 33px;
      text-align: center;
    `,
    Error: styled.div`
    margin-left: 10px;
      color: #c60000;
      margin-top: 0px;
      font-size: 12px;
      position: absolute;
      bottom: -2px;
    `,
  }
};
