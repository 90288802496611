import styled from "styled-components";

const HowItWorksStyles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    max-width: 1440px;
    padding: 0 24px;
    /* width: 100vw; */

    @media (${(props) => props.theme.breakpoints.sm}) {
      padding: 0 24px 65px;
    }
    h1 {
      margin: 100px;
      font-size: 75px;
      font-weight: 500;
    color: #20243A;

      @media (${(props) => props.theme.breakpoints.md}) {
        margin: 100px 0 20px 0;
      }
      @media (${(props) => props.theme.breakpoints.sm}) {
        margin: 0 0 100px 0;
        font-size: 45px;
        font-weight: 500;
      }
    }
  `,
  FlexContainer: styled.div<{ $swap?: boolean }>`
    display: flex;
    justify-content: center;
    gap: 220px;
    width: 100%;
    margin-bottom: 150px;
    height: 100%;
    @media (${(props) => props.theme.breakpoints.md}) {
      flex-direction: ${(props) => (props.$swap ? "column-reverse" : "column")};
      margin-bottom: 30px;
      align-items: center;
      gap: 0;
    }
    @media (${(props) => props.theme.breakpoints.md}) {
      margin-bottom: 75px;
    }
  `,
  Copy: styled.div<{ $copySwap?: boolean }>`
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 480px;
    text-align: left;
    flex-direction: column;
    z-index: 1;
    @media (${(props) => props.theme.breakpoints.md}) {
      text-align: center;
      width: 55%;
    }
    @media (${(props) => props.theme.breakpoints.sm}) {
      width: 300px;
    }

    h3 {
      font-weight: 500;
      text-transform: uppercase;
      color: #7689ff;
      letter-spacing: 1.98px;
      line-height: 22px;
      @media (${(props) => props.theme.breakpoints.sm}) {
        display: ${(props) => (props.$copySwap ? 'none' : 'Customize')}
    }
    }

    h1 {
      margin: 16px 0 20px 0;
      font-weight: 500;
      font-size: 50px;
      line-height: 60px;
      letter-spacing: 0;
    color: #20243A;

      @media (${(props) => props.theme.breakpoints.md}) {
        font-size: 37px;
        line-height: 50px;
      }
      @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 24px;
        line-height: 32px;
        color: #7689ff;
        font-weight: 500;
        margin-bottom: 8px;
        /* content: ${(props) => (props.$copySwap ? 'Customize your personal AI Assistant' : "Create your personal AI Assistant")} */
      }
    }
    p {
      margin-top: 10px;
      font-size: 18px;
      line-height: 24px;
    color: #20243A;

      @media (${(props) => props.theme.breakpoints.md}) {
        font-size: 17px;
      }
      @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  `,
  Image: styled.div`
    width: 100%;
    height: 100%;
    max-width: 633px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 90%;
      width: 105%;
      max-width: 1000px;
    }
  `,
};

export default HowItWorksStyles;
