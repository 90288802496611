import React, { useEffect } from "react";
import SplashPage from "../SplashPage/SplashPage";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        const redirect = async () => {
            await localStorage.removeItem('JWT_TOKEN');
            await localStorage.removeItem('REFRESH_TOKEN');
    
            navigate('/')
        }
        
        redirect();
    }, [])

    return(
        <>
            <SplashPage />
        </>
    )
}

export default Logout;