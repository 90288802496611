import React, { useState, useRef } from "react";
import VideoStyles from "./VideoStyles";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import Play from "../../assets/images/homepage/play.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import VideoThumb from "../../assets/images/homepage/video-thumb.jpg";

const Video = () => {
  const [isPlaying, setPlaying] = useState(false);
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);
  const playerRef = useRef<ReactPlayer>(null);
  return (
    <VideoStyles.Container
      style={{
        backgroundColor: "white",
        position: "relative",
      }}
    >
      {isPlaying && (
        <div
          onClick={() => setPlaying(false)}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0",
            zIndex: "3",
          }}
        />
      )}
      <VideoStyles.Player
        ref={playerRef}
        url="https://vimeo.com/852401587/9058b79fd8"
        height="100%"
        width="100%"
        light={VideoThumb}
        onReady={() => {
          if (!isSmallScreen) {
            setPlaying(true)
          }
          }}
        onEnded={() => playerRef.current && playerRef.current.showPreview()}
        className="Player"
        controls={true}
        playing={isPlaying}
        playIcon={
          <VideoStyles.PlayButton>
            <img style={{height: '100%', width: '100%'}} src={Play} alt="Play video" />
          </VideoStyles.PlayButton>
        }
      />
      {/*  <VideoStyles.PlaySound>
       <VolumeOffIcon
        onClick={playAudio} style={{
          width: "100%",
          height: "100%",
          display: "flex",
          pointerEvents: "none"
        }}/>
      </VideoStyles.PlaySound>*/}
    </VideoStyles.Container>
  );
};

export default Video;
