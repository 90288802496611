import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SplashPage from "./pages/SplashPage/SplashPage";
import Logout from "./pages/Logout";
import { useClient } from "./context/ClientContext";
import LoginModal from "./components/Login/Login";
import SignUpModal from "./components/Login/Signup/Signup";
import ForgotPasswordModal from "./components/Login/ForgotPassword/ForgotPasswordModal";
import GoogleDriveRedirect from "./pages/GoogleDriveRedirect";
import GoogleRedirect from "./pages/GoogleRedirect";
import GoogleDriveAuth from "./pages/GoogleDriveAuth";
import WaitlistSignup from "./pages/WaitlistSignup/WaitlistSignup";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import Webpage from "./pages/Webpage/Webpage";

const Layout = () => {
  const { isLoginOpen, isSignupOpen, isForgotOpen } = useClient();
  return (
    <div
      style={{
        height: "100%",
        width: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: 'center',
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Webpage />}
          />
          <Route
            path="/beta-signup"
            element={<WaitlistSignup />}
          />
          {/* <Route path="/reset_password" element={<PasswordReset />} /> */}
          <Route
            path="/logout"
            element={<Logout />}
          />
          <Route
            path="/google-redirect"
            element={<GoogleRedirect />}
          />
          <Route
            path="/google-drive-auth"
            element={<GoogleDriveAuth />}
          />
          <Route
            path="/google-drive-redirect"
            element={<GoogleDriveRedirect />}
          />
        </Routes>
        {/* {isLoginOpen && <LoginModal />}
        {isSignupOpen && <SignUpModal />}
        {isForgotOpen && <ForgotPasswordModal />} */}
      </BrowserRouter>
      
    </div>
  );
};

export default Layout;
