import React, { useState } from "react";
import SplashPage from "../SplashPage/SplashPage";
import SplashStyles from "../../pages/SplashPage/SplashStyles";
import { Footer } from "../../components/Footer/Footer";
import HowItWorks from "../../components/HowItWorksSection/HowItWorks";
import WebpageStyles from "./WebpageStyles";
import HowCanIUse from "../../components/HowCanIUseSection/HowCanIUse";
import SecurityIntegrations from "../../components/SecurityIntegrationsSection/SecurityIntegrations";
import AboutXureal from "../../components/AboutXureal/AboutXureal";
import { DialogContent } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { LoginStyles } from "../../components/Login/LoginStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faXmark, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

const Webpage = () => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);
  const useLargeBackground = useMediaQuery("(min-width: 1800px)");
  const [isReformOpen, setReformOpen] = useState(false);
  return (
    <>
      <WebpageStyles.SectionContainer $useBackground={!useLargeBackground}>
        {useLargeBackground && <WebpageStyles.LargeScreenBackground />}
        <SplashPage />
        <HowItWorks />
        <HowCanIUse />
        <SecurityIntegrations />
        <AboutXureal />
        <WebpageStyles.LetsTalkContainer>
          <h1>
            Need a tailor-made AI Assistant for your unique business needs?
          </h1>
          <button
            onClick={() => setReformOpen(true)}
            style={{
              fontWeight: "bold",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px'
            }}
          >
            <span 
            style={{ paddingRight: '20px' }}
            >
              Let's talk
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                size='lg'
              />
            </span>
          </button>
        </WebpageStyles.LetsTalkContainer>
        <SplashStyles.HomeFooter>
          <Footer />
        </SplashStyles.HomeFooter>
      </WebpageStyles.SectionContainer>

      {/* Reform Dialog window */}
      <WebpageStyles.ReformDialog
        fullScreen={isSmallScreen}
        scroll="paper"
        open={isReformOpen}
      >
        <LoginStyles.CloseButton onClick={() => setReformOpen(false)}>
          <FontAwesomeIcon icon={faXmark} />
        </LoginStyles.CloseButton>
        <DialogContent>
          <iframe
            style={{ height: "900px", width: "100%" }}
            src={"https://forms.reform.app/9T1ZtL/feature-requests/uvdI5S"}
          />
        </DialogContent>
      </WebpageStyles.ReformDialog>
    </>
  );
};

export default Webpage;
