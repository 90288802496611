import { ThemeProvider } from "styled-components";
import React, { ReactNode } from 'react';

interface ThemeProps {
  children: ReactNode
}

const theme = {
  colors: {
    primary: {
      main: "#7689FF",
      dark: "#233FD3",
      light: "#DDE6FF",
    },
    // Blues
    cornflowerBlue: '#7689FF',
    // Purples and Pinks
    blueViolet: '#6A3AE7',
    hotMagenta: '#FF22D3',
    neutral: {
      main: "#888DAC",
      dark: "#20243A",
      light: "#B19D9D",
      white: "#FFFFFF",
      black: "#000000",
    },
    creme: '#FFF6F0',
    lightTan: "#B19D9D",
    beige: '#efdcda',
  },
  breakpoints: {
      xs: 'max-width: 480px',
      sm: 'max-width: 768px',
      md: 'max-width: 1024px',
  },
  typography: {
    h1: {
      fontSize: '40px',
      fontWeight: '300'
    },
    h2: {
      fontSize: '30px',
      fontWeight: '400'
    },
    h3: {
      fontSize: '25px'
    },
    h4: {
      fontSize: '20px',
      fontWeight: '400'
    },
    h5: {
      fontSize: '18px'
    },
    link: {
      fontSize: '16px',
      border: 'none',
      boxShadow: 'none',
      background: 'transparent',
      fontWeight: '300',
      fontFamily: 'inherit'
    }

  }
}

const Theme = ({ children }: ThemeProps) => {
  return(
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  )
}


export default Theme;
