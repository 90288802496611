import React from "react";
import HowCanIUseStyles from "./HowCanIUseStyles";

// Assets
import UserHeadset from "../../assets/icons/user-headset.png";
import MagnifyingGlass from "../../assets/icons/magnifying-glass.svg";
import Brain from "../../assets/icons/brain-icon.png";
import Users from "../../assets/icons/users-icon.png";

const HowCanIUse = () => {
  return (
    <HowCanIUseStyles.Container>
      <h1>How can I use Infobox</h1>
      <HowCanIUseStyles.FlexContainer>
        <HowCanIUseStyles.Content>
          <HowCanIUseStyles.Icon>
            <img
              src={UserHeadset}
              alt=""
            />
          </HowCanIUseStyles.Icon>
          <h2>Streamlines Customer Support</h2>
          <p>
            Boost customer support with a website-ready AI Assistant. Install it
            easily using our code.
          </p>
        </HowCanIUseStyles.Content>
        <HowCanIUseStyles.Content>
          <HowCanIUseStyles.Icon>
            <img
              src={MagnifyingGlass}
              alt=""
              style={{
                width: "45px",
                height: "40px",
              }}
            />
          </HowCanIUseStyles.Icon>
          <h2>Simplify Data Analysis</h2>
          <p>
            Effortlessly review and understand large volume of data like
            customer reviews, user requests.
          </p>
        </HowCanIUseStyles.Content>
        <HowCanIUseStyles.Content>
          <HowCanIUseStyles.Icon>
            <img
              src={Brain}
              alt=""
            />
          </HowCanIUseStyles.Icon>
          <h2>Train Your Assistant</h2>
          <p>
            Optimize your assistant with new or updated data through retraining.
          </p>
        </HowCanIUseStyles.Content>
        <HowCanIUseStyles.Content>
          <HowCanIUseStyles.Icon>
            <img
              src={Users}
              alt=""
              style={{
                width: "43px",
              }}
            />
          </HowCanIUseStyles.Icon>
          <h2>Knowledge Sharing</h2>
          <p>
            Share your knowledge with colleagues and friends for a collaborative
            experience.
          </p>
        </HowCanIUseStyles.Content>
      </HowCanIUseStyles.FlexContainer>
    </HowCanIUseStyles.Container>
  );
};

export default HowCanIUse;
