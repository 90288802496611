import styled from "styled-components";
import ReactPlayer from "react-player";

const VideoStyles = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;
    height: auto;
    padding-bottom: 56.25%;
    border-radius: 25px;
    /* box-shadow: 0 2px 37px 0 rgba(41, 51, 107, 0.25); */
    @media (${props => props.theme.breakpoints.sm}) {
      margin-top:-45px;
      margin-bottom: 65px;
    }
  `,
  Player: styled(ReactPlayer)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: auto;
    & .react-player__play-icon {
      display: none;
    }
    & .react-player__shadow {
      display: none !important;
    }
  `,
  PlaySound: styled.button`
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: none;
  `,
  PlayButton: styled.button`
    border: none;
    cursor: pointer;
    height: 90px;
    width: 90px;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @media (${props => props.theme.breakpoints.sm}) {
      font-size: 14px;
      width: 60%;
      max-width: 180px;
      height: 68px;
      width: 68px;
    }
  `,
};

export default VideoStyles;
