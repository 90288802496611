import React from 'react'
import HowItWorksStyles from './HowItWorksStyles'

// Assets
import Train from '../../assets/graphics/train-graphic.png'
import Widget from '../../assets/graphics/customize-image.png'
import IntegrateGraphic from '../../assets/graphics/integrate.png'

const HowItWorks = () => {
  return (
    <HowItWorksStyles.Container>
        <h1>How it works</h1>
        <HowItWorksStyles.FlexContainer
        >
            <HowItWorksStyles.Image>
                <img src={Widget} alt="AI-widget" />
            </HowItWorksStyles.Image>
            <HowItWorksStyles.Copy  $copySwap>
                <h3>Create</h3>
                <h1>Customize your personal AI Assistant</h1>
                <p>Personalize your AI assistant with a name and customize its appearance. Choose widget color, logo, and avatar for a unique touch.</p>
            </HowItWorksStyles.Copy>
        </HowItWorksStyles.FlexContainer>

        <HowItWorksStyles.FlexContainer
        $swap>
            <HowItWorksStyles.Copy $copySwap>
                <h3>Train</h3>
                <h1>Import content to your private AI database</h1>
                <p>Easily import content, train your AI, to maintain ownership and confidentiality. Re-sync, deactivate, or delete data as needed to stay in control.</p>
            </HowItWorksStyles.Copy>
            <HowItWorksStyles.Image>
                <img src={Train} alt=""/>
            </HowItWorksStyles.Image>
        </HowItWorksStyles.FlexContainer>

        <HowItWorksStyles.FlexContainer
        >
            <HowItWorksStyles.Image>
                <img src={IntegrateGraphic} alt="" />
            </HowItWorksStyles.Image>
            <HowItWorksStyles.Copy $copySwap>
                <h3>Integrate</h3>
                <h1>Collaborative information sharing</h1>
                <p>Integrate our AI assistant on your website or share its link with friends and colleagues internally for easy collaboration on common knowledge source.</p>
            </HowItWorksStyles.Copy>
        </HowItWorksStyles.FlexContainer>
    </HowItWorksStyles.Container>
  )
}

export default HowItWorks