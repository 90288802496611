import React, { useEffect } from "react";
import axios from "axios";
import { getGoogleAccess } from "../actions";
import { useNavigate } from "react-router-dom";

const GoogleRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of the 'code' parameter
    const code = urlParams.get("code");
    if (code) {
      getGoogleAccess({ accessToken: code }).then(
        (res: { result: string; JWT_TOKEN: string; REFRESH_TOKEN: string, ENCODING: string }) => {
          console.log('google response', res);
          if (res.result === "SUCCESS") {
            localStorage.setItem("JWT_TOKEN", res.JWT_TOKEN);
            localStorage.setItem("REFRESH_TOKEN", res.REFRESH_TOKEN);
            // initJWT(res.JWT_TOKEN)
            //   .then(() => {
            //     console.log("INIT JWT");
            //   })
            //   .catch((err: any) => console.error(err));
          }
          window.opener.postMessage(
            JSON.stringify({result: res.result, data: res.ENCODING}),
            "*"
          );
          window.close();
        }
      );
    }
  }, []);
  return <></>;
};

export default GoogleRedirect;
