import Layout from "./Layout";
import { ThemeProvider } from "styled-components";
import Theme from "./theme";
import ClientProvider from "./context/ClientContext";

function App() {
  return (
    <>
      <ClientProvider>
        <Theme>
          <Layout />
        </Theme>
      </ClientProvider>
    </>
  );
}

export default App;
