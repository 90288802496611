import styled from "styled-components";

const HowCanIUseStyles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    background-color: rgb(254, 243, 240);
    padding: 191px 30px 125px;
    @media (${(props) => props.theme.breakpoints.sm}) {
      padding: 100px 30px; 
    }
    h1 {
      font-size: 75px;
      font-weight: 400;
      margin-bottom: 131px;
      @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 45px;
        line-height: 50px;
        margin-bottom: 95px;
      }
    }
  `,
  FlexContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 100px;
    max-width: 1440px;

    `,
  Content: styled.div`
    background-color: #ffffff;
    max-width: 497px;
    width: 100%;
    height: 252px;
    border-radius: 55px;
    position: relative;
    margin: 0 0 125px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    box-shadow: 0 2px 15px 0 rgba(197, 156, 140, 0.31);
    @media (${(props) => props.theme.breakpoints.md}) {
      max-width: unset;
      padding: 35px 25px 15px;
      margin: 0 0 105px 0;
      height: 202px;
      border-radius: 35px;
    }

    h2 {
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
      
      @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 20px;
        line-height: 25px;
        width: 200px;
      }
    }
    p {
      padding-top: 10px;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.3px;
      line-height: 24px;

      @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 13px;
        line-height: 18px;
      }
    }
  `,
  Icon: styled.div`
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    transform: translateX(-50%);
    border: #fef6f0 solid 10px;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: "";
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      box-shadow: inset rgba(197, 156, 140, 0.2) 0px -10px 8px 0px; /* Apply the shadow to the border */
      pointer-events: none;
      border-radius: 50%;
      z-index: 1;
    }
    & img {
      height: 38px;
      width: 35px;
    }
  `,
};

export default HowCanIUseStyles;
