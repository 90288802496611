import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SplashStyles from "./SplashStyles";
import Video from "../../components/Video/Video";
import BetaStyles from "../../components/BetaSignUp/BetaStyles";
import ConfirmModal from "../../components/BetaSignUp/ConfirmModal";
import {
  faArrowCircleRight,
  faArrowRightLong,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { useClient } from "../../context/ClientContext";

// Assets
import InfoBoxLogo from "../../assets/images/branding/infobox/infoboxLogo.png";
import { LoginStyles } from "../../components/Login/LoginStyles";

const redirectURL = process.env.REACT_APP_REDIRECT;

const SplashPage = () => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [waitlistEmail, setWaitlistEmail] = useState("");
  const [textFieldVisible, setTextFieldVisible] = useState(false);
  const { setSignupOpen, setLoginOpen, authorized, infoboxaiBETA } =
    useClient();
  const [errorMessage, setErrorMessage] = useState("");
  const urlParams = new URLSearchParams(window.location.search);

  const handleBetaSignUp = async () => {
    const signUpUser = () => {
      if (
        !waitlistEmail.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$")
      ) {
        setErrorMessage("Please enter a valid email");
        return;
      }
      const dataObj = {
        firstname: waitlistEmail.split("@")[0],
        lastname: waitlistEmail.split("@")[1],
        email: waitlistEmail,
        whitelisted: false,
        service: "infoboxai",
      };
      console.log("data obj", dataObj);
      setConfirmOpen(true);

      infoboxaiBETA.postSubscriberData(dataObj).then((res: any) => {
        console.log("beta res", res);
        setWaitlistEmail("");
      });
    };
    infoboxaiBETA.getSubcriberByEmail(waitlistEmail).then((res: any) => {
      if (res.result === "FAILED") {
        signUpUser();
        setErrorMessage("");
      } else {
        setErrorMessage(
          "You are already waitlisted. We will contact you shortly!"
        );
      }
    });
  };

  const handleEmailChange = (newValue: string) => {
    setWaitlistEmail(newValue);
  };

  const handleSignUpClick = () => {
    if (process.env.REACT_APP_REDIRECT)
      window.location.href = process.env.REACT_APP_REDIRECT + "/signup";
  };

  const handleLoginClick = () => {
    if (process.env.REACT_APP_REDIRECT)
      window.location.href = process.env.REACT_APP_REDIRECT + "/login";
  };

  return (
    <SplashStyles.Container>
      <SplashStyles.FlexColumn
        style={{
          width: "100%",
          maxWidth: "1440px",
          alignItems: "center",
          marginBottom: "29px",
        }}
      >
        <SplashStyles.HomeHeader>
          <SplashStyles.LargeScreenWrapper>
            {!isSmallScreen && <SplashStyles.ProductHunt />}
            <SplashStyles.HomeLogo src={InfoBoxLogo} />
            <SplashStyles.FlexRow>
              <SplashStyles.HeaderButton onClick={handleSignUpClick}>
                Sign up
              </SplashStyles.HeaderButton>
              <SplashStyles.HeaderButton
                onClick={handleLoginClick}
                $bgColor="#EAECFF"
              >
                Log in
              </SplashStyles.HeaderButton>
            </SplashStyles.FlexRow>
          </SplashStyles.LargeScreenWrapper>
        </SplashStyles.HomeHeader>
        <SplashStyles.FlexColumn
          style={{ alignItems: "center", maxWidth: "80%", zIndex: 1 }}
        >
          <SplashStyles.HomeTitle>
            Your Knowledge Powered by{" "}
            <SplashStyles.Highlight>AI</SplashStyles.Highlight>
          </SplashStyles.HomeTitle>
          <SplashStyles.HomeSubtitle>
            Your personal{" "}
            <SplashStyles.Highlight style={{ fontWeight: 600 }}>
              AI
            </SplashStyles.Highlight>{" "}
            Assistant to make sense of all of your information under one roof.{" "}
          </SplashStyles.HomeSubtitle>
        </SplashStyles.FlexColumn>
      </SplashStyles.FlexColumn>
      <SplashStyles.FlexColumn
        $justifyContent="center"
        $margin={isSmallScreen ? "47px 0 0 0" : "95px 0 55px 0"}
        style={{ width: "100%" }}
      >
        <SplashStyles.ImageContainer>
          <div
            style={{ maxWidth: isSmallScreen ? "90%" : "60%", width: "95%" }}
          >
            <Video />
          </div>
        </SplashStyles.ImageContainer>
      </SplashStyles.FlexColumn>
      {/* <SplashStyles.HomeFooter>
        <Footer />
      </SplashStyles.HomeFooter> */}
      <ConfirmModal setConfirmOpen={setConfirmOpen} isOpen={isConfirmOpen} />
    </SplashStyles.Container>
  );
};

export default SplashPage;
