import React, { useEffect } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import { authorizeGoogleDrive, getGoogleAccess } from "../actions";

const GoogleDriveAuth = () => {
  useEffect(() => {
    console.log("HELLO");
    authorizeGoogleDrive().then((res: any) => {
      console.log(res);
      if (res.url) {
        window.open(res.url, "_self");
      }
    });
  }, []);
  return <></>;
};

export default GoogleDriveAuth;
