import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HomeBackground from "../../assets/images/homepage/home-bg.svg";

type FlexBoxAtttributes = {
  $justifyContent?: string;
  $alignItems?: string;
  $height?: string;
  $width?: string;
  $padding?: string;
  $margin?: string;
  $flexWrap?: string;
  $flex?: string;
};

const SplashStyles = {
  Container: styled.div`
    width: 100%;
    max-width: 1440px;
    font-family: halyard-display !important;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: transparent;
    @media (${(props) => props.theme.breakpoints.md}) {
      background-size: 100% 90%;
    }
    @media screen and (max-width: 523px) {
      background-size: 100% 120%;
    }
  `,
  LargeScreenWrapper: styled.div`
    height: 100%;
    width: 100%;
    padding: 0 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
  `,
  HomeHeader: styled.header`
    width: 100%;
    height: 171px;
    display: flex;
    justify-content: center;
    z-index: 1;
    @media (${(props) => props.theme.breakpoints.sm}) {
      height: 75px;
      padding-right: 10px;
      padding-left: 10px;
      margin-bottom: 36px;
    }
  `,
  SearchBar: styled(TextField)`
    && {
      height: 37px;
      border: 1px solid #f1e6e0;
      border-radius: 20.5px;
      width: 312px;
      max-width: 40%;
      & .MuiInputBase-root {
        height: 100%;
        margin-bottom: 0;
        border-radius: 41px;
        border: none;
      }
      & .MuiOutlinedInput-input {
        height: 100%;
        border: none;
        font-size: 16px;
        color: ${(props) => props.theme.colors.neutral.dark};
        padding-left: 9px;
        font-weight: 300;
      }
      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  `,
  SearchIcon: styled(FontAwesomeIcon)`
    height: 17px;
  `,
  BackIcon: styled.div`
    position: absolute;
    top: 40px;
    left: 30px;
  `,
  FlexColumn: styled.div<FlexBoxAtttributes>`
    display: flex;
    flex-direction: column;
    margin: ${(props) => (props.$margin ? props.$margin : "0")};
    padding: ${(props) => (props.$padding ? props.$padding : "0")};
    width: ${(props) => (props.$width ? props.$width : "auto")};
    height: ${(props) => (props.$height ? props.$height : "auto")};
    flex: ${(props) => (props.$flex ? props.$flex : "unset")};
    justify-content: ${(props) =>
      props.$justifyContent ? props.$justifyContent : "center"};
    align-items: ${(props) =>
      props.$alignItems ? props.$alignItems : "center"};
  `,
  FlexRow: styled.div<FlexBoxAtttributes>`
    display: flex;
    align-items: center;
    margin: ${(props) => (props.$margin ? props.$margin : "0")};
    padding: ${(props) => (props.$padding ? props.$padding : "0")};
    width: ${(props) => (props.$width ? props.$width : "auto")};
    height: ${(props) => (props.$height ? props.$height : "auto")};
    flex: ${(props) => (props.$flex ? props.$flex : "unset")};
    justify-content: ${(props) =>
      props.$justifyContent ? props.$justifyContent : "center"};
    align-items: ${(props) =>
      props.$alignItems ? props.$alignItems : "center"};
  `,

  HomeTitle: styled.h1`
    font-size: clamp(46px, 5.6vw, 77px);
    font-weight: 500;
    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center;
    color: #20243a;

    @media (${(props) => props.theme.breakpoints.sm}) {
      line-height: 40px;
      font-size: 36px;
    }
  `,
  HomeSubtitle: styled.h3`
    font-size: 22px;
    text-align: center;
    margin-bottom: 40px;
    color: #20243a;
    font-weight: 300;
    max-width: 80%;
    @media (${(props) => props.theme.breakpoints.sm}) {
      font-size: 15px;
      margin-bottom: 29px;
      max-width: unset;
    }
  `,
  ImageContainer: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    @media (${(props) => props.theme.breakpoints.sm}) {
      margin: 40px 0 20px;
    }
  `,
  ProductHunt: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    img {
      @media (${(props) => props.theme.breakpoints.sm}) {
        height: 34px;
        /* width: 28px; */
      }
    }
  `,
  HomeLogo: styled.img`
    height: 58px;
    padding-left: 75px;
    @media (${(props) => props.theme.breakpoints.sm}) {
      height: 40px;
      padding-left: 0;
    }
  `,
  FloatingImage: styled.img<{
    $top?: string;
    $bottom?: string;
    $right?: string;
    $left?: string;
    $width: string;
    $minWidth?: string;
    $maxWidth?: string;
  }>`
    position: absolute;
    top: ${(props) => (props.$top ? props.$top : "unset")};
    bottom: ${(props) => (props.$bottom ? props.$bottom : "unset")};
    right: ${(props) => (props.$right ? props.$right : "unset")};
    left: ${(props) => (props.$left ? props.$left : "unset")};
    width: ${(props) => props.$width};
    height: auto;
    pointer-events: none;
    min-width: ${(props) => (props.$minWidth ? props.$minWidth : "70px")};
    @media (${(props) => props.theme.breakpoints.sm}) {
      display: none;
    }
  `,
  HomeFooter: styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background-color: #7787ff;
    color: #ffffff;
    @media (max-width: 1064px) {
      
    }
    @media (${(props) => props.theme.breakpoints.sm}) {
      flex-direction: column;
      text-align: center;
      padding: 35px 0 0;
      height: fit-content;
      min-width: 100%;
    }
    @media (${(props) => props.theme.breakpoints.xs}) {
      padding: 35px 0 0;
    }
  `,
  Copyright: styled.div`
    height: 40px;
    background-color: #372e2e;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  GetStartedFooter: styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    
    @media (${(props) => props.theme.breakpoints.md}) {
      padding-bottom: 50px;
      align-items: center;
      width: 60%;
      text-align: center;
    }
    h3 {
      font-weight: 400;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: 0;
      padding: 40px 0 50px 0;
      @media (${(props) => props.theme.breakpoints.md}) {
        font-size: 40px;
        font-weight: 500;
      }
    }
  `,
  FooterWaitlistButton: styled(Button)`
    && {
      height: 46px;
      text-transform: none;
      min-width: 71px;
      max-width: 250px;
      font-size: 18px;
      font-family: halyard-display;
      font-weight: 500;
      line-height: 29px;
      border-radius: 31px;
      padding: 13px 23px 13px 25px;
      color: #7689ff;
      background-color: #ffffff;
      box-shadow: 0 2px 7px 0 rgba(117, 114, 109, 0.13);
      white-space: nowrap;
      &:hover {
        background-color: #F5F5F5;
      }
      svg {
        margin-left: 21px;
      }
    }
  `,
  PoweredBy: styled.div`
    display: flex;
    align-items: center;
    @media (${(props) => props.theme.breakpoints.md}) {
      margin-bottom: 20px;
    }
    p {
      font-weight: 400;
      color: #fff;
      white-space: nowrap;
      margin-right: 8px;
      font-size: 19px;
      @media (${(props) => props.theme.breakpoints.sm}) {
        margin-right: 4px;
        font-size: 16px;
        font-weight: 500;
      }
    }
    img {
      width: 138px;
      @media (${(props) => props.theme.breakpoints.sm}) {
        width: auto;
      }
    }
  `,
  FooterColumn: styled.div<{ $setWidth?: string }>`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: ${(props) => (props.$setWidth ? props.$setWidth : "auto")};
    padding-top: 80px;
    @media (${(props) => props.theme.breakpoints.md}) {
      padding-top: 0;
    }
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 20px;
      @media (${(props) => props.theme.breakpoints.sm}) {
        padding-bottom: 0;
      }
    }
  `,
  TextLink: styled.button`
    color: #ffffff;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    font-weight: 400; // not working
    cursor: pointer;
    @media (${(props) => props.theme.breakpoints.sm}) {
      /* margin-bottom: 25px; */
      font-size: 14px;
      margin: 20px 10px 10px 0;
    }
    @media (${(props) => props.theme.breakpoints.xs}) {
      padding: 0 2px;
    }
  `,
  LoveMessage: styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 16px;
    letter-spacing: 1.05px;
    opacity: 0.98;
    @media (max-width: 768px) {
      margin-bottom: 16px;
      justify-content: center;
      font-size: 14px;
    }
  `,
  SocialLInk: styled.img`
    height: 30px;
    width: auto;
    margin-right: 20px;
    cursor: pointer;
    @media (${(props) => props.theme.breakpoints.sm}) {
      height: 30px;
    }
  `,
  SignupButton: styled.button`
    font-size: 18px;
    color: white;
    line-height: 29px;
    padding: 10px 22px;
    border: none;
    cursor: pointer;
    border-radius: 24px;
    box-shadow: 0 0 14px 0 rgba(49, 68, 181, 0.55);
    background: #7689ff;
  `,
  AILogoContainer: styled.img`
    height: 71px;
    width: 71px;
    border-radius: 50%;
    position: fixed;
    bottom: 3vw;
    right: 3vw;
    @media (${(props) => props.theme.breakpoints.sm}) {
      display: none;
    }
  `,
  LegalFooter: styled.div`
    font-size: 12px;
    color: #ffffff;
    font-weight: 300;
    width: 100%;
    background-color: #372e2e;
    padding: 13px;
    @media (${(props) => props.theme.breakpoints.sm}) {
      font-size: 12px;
    }
  `,
  LegalTag: styled.a`
    text-decoration: none;
    color: #372e2e;
    font-size: 12px;
    margin-left: 7px;
  `,
  ErrorMessage: styled.div`
    font-size: 12px;
    color: white;
    width: 100%;
    text-align: center;
  `,
  SlideContainer: styled.div`
    width: 70%;
    max-width: 690px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 20px;
    background-color: white;
    width: 540px;
    height: 600px;
    border-radius: 30px;
    padding: 50px;
    box-sizing: border-box;
  `,
  LargeTextField: styled(TextField)`
    && {
      height: 100%;
      border-radius: 41px;
      border: 1px solid ${(props) => props.theme.colors.primary.main};
      background-color: #fff;
      width: 600px;
      overflow: hidden;
      margin: 0 auto;
      & .MuiInputBase-root {
        height: 100%;
        margin-bottom: 0;
        border-radius: 41px;
        border: none;
        padding-right: 9px;
        & input:-webkit-autofill {
          -webkit-text-fill-color: inherit !important;
          -webkit-box-shadow: 0 0 0 1000px white inset !important;
        }
      }
      & .MuiOutlinedInput-input {
        height: 100%;
        border: none;
        font-size: 18px;
        padding-left: 32px;
        flex: 1;
      }
      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }
      @media (${(props: any) => props.theme.breakpoints.md}) {
        height: 100%;
        font-size: 16px;
        max-width: 100%;
        & .MuiInputBase-root {
          padding-right: 11px;
        }
        & .MuiOutlinedInput-input {
          font-size: 16px;
          padding-left: 18px;
        }
      }
    }
  `,
  LargeTextFieldButton: styled(Button)`
    && {
      height: 46px;
      text-transform: none;
      min-width: 71px;
      font-size: 22px;
      font-family: halyard-display;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 29px;
      border-radius: 31px;
      color: #fff;
      background-color: ${(props) => props.theme.colors.primary.main};
      box-shadow: 0 2px 7px 0 rgba(117, 114, 109, 0.13);
      /* width: 250px; */
      @media (${(props: any) => props.theme.breakpoints.md}) {
        height: 36px;
        font-size: 18px;
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.primary.dark};
      }
    }
  `,
  HeaderButton: styled.button<{ $bgColor?: string }>`
    height: 38px;
    width: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 19px;
    color: ${(props) => props.theme.colors.primary.main};
    font-weight: 400;
    background: ${(props) => (props.$bgColor ? props.$bgColor : "transparent")};
    border: none;
    cursor: pointer;
    @media (${(props) => props.theme.breakpoints.sm}) {
      font-size: 15px;
      width: 82px;
      height: 34px;
    }
  `,
  Highlight: styled.span`
    color: ${(props) => props.theme.colors.primary.main};
  `,
  TextField: styled(TextField)`
    && {
      width: 100%;
    }
  `,
};

export default SplashStyles;
