import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../context/ClientContext";

const WaitlistSignup = () => {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const encodedEmail = urlParams.get("user_invite");

  useEffect(() => {
    if (encodedEmail) {
      window.location.href =
        process.env.REACT_APP_REDIRECT + `/signup?user_invite=${encodedEmail}`;
    } else {
      navigate("/");
    }
  }, []);

  return (
    // <SplashStyles.Container>
    //   <div style={{ marginBottom: "15px" }}>
    //     <SplashStyles.HomeHeader style={{ justifyContent: "center" }}>
    //       <SplashStyles.HomeLogo src={InfoBoxLogo} />
    //     </SplashStyles.HomeHeader>
    //     <SplashStyles.HomeTitle style={{ fontSize: "48px" }}>
    //       Your Knowledge Powered by AI
    //     </SplashStyles.HomeTitle>
    //     {waitlistEmail && (
    //       <SplashStyles.HomeSubtitle
    //         style={{ margin: "0 !important", maxWidth: "unset" }}
    //       >
    //         Please sign up using {waitlistEmail}
    //       </SplashStyles.HomeSubtitle>
    //     )}

    //     <SplashStyles.FlexRow>
    //       <LoginStyles.Container
    //         style={{
    //           borderRadius: isSmallScreen ? "0" : "30px",
    //           position: isSmallScreen ? "absolute" : "relative",
    //           top: isSmallScreen ? "0" : "unset",
    //           left: isSmallScreen ? "0" : "unset",
    //         }}
    //       >
    //         {isSmallScreen && (
    //           <img className="logo" src={InfoBoxLogo} alt="logo-icon" />
    //         )}
    //         {!emailSignUpOpen ? (
    //           <SignUpOptions onEmailClick={handleEmailClick} />
    //         ) : (
    //           <SignUpWithEmail
    //             open={emailSignUpOpen}
    //             setOpenEmailSignUp={setOpenEmailSignUp}
    //             onLoginClick={() => {}}
    //           />
    //         )}
    //       </LoginStyles.Container>
    //     </SplashStyles.FlexRow>
    //   </div>
    //   <SplashStyles.HomeFooter>
    //     <img src={Xureal} style={{ width: "168px" }} alt="Powered by Xureal" />
    //     {isSmallScreen && (
    //       <SplashStyles.FlexRow
    //         style={{ justifyContent: "space-between", width: "100%" }}
    //       >
    //         <SplashStyles.LegalFooter
    //           style={{ marginRight: isSmallScreen ? "0" : "4vw" }}
    //         >
    //           &copy; 2023 All Rights Reserved. Xureal &reg;
    //         </SplashStyles.LegalFooter>
    //         <SplashStyles.FlexRow style={{ justifyContent: "center" }}>
    //           <SplashStyles.LegalTag
    //             target="_blank"
    //             href="https://legal.infobox.ai/infoboxai-terms-and-conditions.html"
    //             style={{ marginRight: isSmallScreen ? "1vw" : "0" }}
    //           >
    //             Terms of Service
    //           </SplashStyles.LegalTag>
    //           <SplashStyles.LegalTag
    //             target="_blank"
    //             href="https://legal.infobox.ai/infoboxai-privacy-policy.html"
    //           >
    //             Privacy Policy
    //           </SplashStyles.LegalTag>
    //         </SplashStyles.FlexRow>
    //       </SplashStyles.FlexRow>
    //     )}
    //     {!isSmallScreen && (
    //       <SplashStyles.FlexRow>
    //         <SplashStyles.LegalFooter style={{ marginRight: "4vw" }}>
    //           &copy; 2023 All Rights Reserved. Xureal &reg;
    //         </SplashStyles.LegalFooter>
    //         <SplashStyles.LegalTag
    //           target="_blank"
    //           href="https://legal.infobox.ai/infoboxai-terms-and-conditions.html"
    //           style={{ marginRight: "24px" }}
    //         >
    //           Terms of Service
    //         </SplashStyles.LegalTag>
    //         <SplashStyles.LegalTag
    //           target="_blank"
    //           href="https://legal.infobox.ai/infoboxai-privacy-policy.html"
    //         >
    //           Privacy Policy
    //         </SplashStyles.LegalTag>
    //       </SplashStyles.FlexRow>
    //     )}
    //     <SplashStyles.AILogoContainer src={AIIcon} alt="AI" />
    //     <div style={{ width: "169px" }} />
    //   </SplashStyles.HomeFooter>
    // </SplashStyles.Container>
    <></>
  );
};

export default WaitlistSignup;
