import styled from "styled-components";

const AboutXurealStyles = {
  Container: styled.div`
    background-color: rgb(254, 243, 240);
    width: 100%;
    padding: 199px 0 200px 0;
    @media (${(props) => props.theme.breakpoints.md}) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 90px 0 80px 0;
    }
  `,
  Content: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    margin: auto;
    /* gap: 3%; */
    @media (max-width: 1144px) {
      flex-direction: column;
      /* gap: 5%; */
      padding: 0 24px;
      text-align: center;
    }
    span {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      max-width: 100%;
      width: 100%;
      @media (max-width: 1144px) {
        justify-content: center;
    }
    h1 {
      font-size: 50px;
      font-weight: 500;
      line-height: 60px;
      padding: 0 10px 10px 0;
      color: #20243a;
    }
    img {
      height: 51px;
      width: 201px;
    }
    @media (max-width: 1144px) {
      margin-left: none;
      span {
        text-align: center;
        justify-content: center;
      }
      h1 {
        font-size: 50px;
        color: ${props => props.theme.colors.primary.main}
      }
      img {
        height: 47px;
        width: auto;
      }
    }
  }
  `,
  ImgContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @media (${(props) => props.theme.breakpoints.sm}) {
      margin-bottom: 30px;
    }
    @media (${(props) => props.theme.breakpoints.sm}) {
      img {
        height: auto;
        width: 100%;
      }
    }
  `,
  CopyContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    max-width: 419px;
    width: 100%;
    height: 100%;
    padding: 0px 20px;

    @media (max-width: 1144px) {
      text-align: center;
      padding-top: 20px;
      width: 100%;
    }

    h3 {
      height: 18px;
      width: 100%;
      color: #7689ff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1.78px;
      line-height: 18px;
      text-transform: uppercase;
      margin-top: 20px;
      margin-bottom: 15px;
    }

    span {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      max-width: 100%;
      width: 100%;
      h1 {
        font-size: 50px;
        font-weight: 500;
        line-height: 60px;
        padding: 0 10px 10px 0;
        color: #20243a;
      }
      img {
        height: 51px;
        width: 201px;
      }
      @media (${(props) => props.theme.breakpoints.md}) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      @media (${(props) => props.theme.breakpoints.sm}) {
        margin-left: none;
        h1 {
          font-size: 38px;
        }
        img {
          height: 39px;
          width: 142px;
        }
      }
    }

    p {
      font-size: 18px;
      font-weight: 300px;
      line-height: 24px;
      margin-top: -12px;
      letter-spacing: 0.4px;
      width: 100%;
      color: #20243a;

      @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  `,
};

export default AboutXurealStyles;
