import React from "react";
import SIStyles from "./SecurityIntegrationsStyles";

// Assets
import Encryption from "../../assets/images/encryption.png";
import SSO from "../../assets/icons/SSO.png";
import Google from "../../assets/images/branding/google/google@2x.png";
import Microsoft from "../../assets/images/branding/microsoft.png";
import Hubspot from "../../assets/icons/hubspot@2x.png";
import Zendesk from "../../assets/icons/Zendesk.png";

const SecurityIntegrations = () => {
  return (
    <SIStyles.Container>
      <SIStyles.Content>
        <h3>Security</h3>
        <h1>Proactive data protection.</h1>
        <p>
          A proactive approach to data protection builds privacy and security
          into everything we do.
        </p>
        <hr 
        style={{
          width: '80%'
        }}/>
        <SIStyles.IconContainer $justify="flex-start">
          <img
            src={Encryption}
            alt=""
            style={{
              marginRight: "60px",
              width: "145px",
              height: "65px",
            }}
          />
          <img
            src={SSO}
            alt=""
            style={{
              width: "114px",
              height: "65px",
            }}
          />
        </SIStyles.IconContainer>
      </SIStyles.Content>
      <SIStyles.Content>
        <h3>Integrations</h3>
        <h1>Connect your favorite tools.</h1>
        <p>
          Seamlessly integrate Infobox with your favorite tools and apps to
          deliver unified end-to-end experiences.
        </p>
        <hr 
        style={{
          width: '80%'
        }}/>
        <SIStyles.IconContainer>
          <SIStyles.IconImage
            src={Google}
            alt=""
          />
          <SIStyles.IconImage
            src={Microsoft}
            alt=""
          />
          <SIStyles.IconImage
            src={Hubspot}
            alt=""
          />
          <SIStyles.IconImage
            src={Zendesk}
          />
        </SIStyles.IconContainer>
      </SIStyles.Content>
    </SIStyles.Container>
  );
};

export default SecurityIntegrations;
